import React from "react"
//import { Link } from "gatsby"
import { MDBContainer, MDBRow, MDBCol } from "mdbreact"
//import Layout from "../components/layout"
import SEO from "../components/seo"
import Pageheader from '../Utils/pageheader';

const ourtemple = () => (
  <>
    <MDBContainer className="py-5">
      <SEO title="About Temple | Hindu Temple Association of Iowa" />
      <h1 className="title font-weight-bolder text-primary">About Our Temple</h1>
      <Pageheader />
      <h2 className="title font-weight-bold orange-text">Welcome to Eastern Iowa Hindu Temple</h2>
      <p className=" text-justify">The Hindu Temple Association of Eastern Iowa was established and incorporated as non-profit corporation under the Iowa non-profit corporation Act,
        Chapter 504A, 1999. Select the link below to read article of incorporation and by-laws:</p>
      <h3 className="title font-weight-bolder text-secondary"> <a href="https://iowaht.s3.us-east-2.amazonaws.com/pdf/Restated_Articles_of_Inc.pdf" target="_blank" rel="noopener noreferrer">Article of Incorporation</a> and <a href="https://iowaht.s3.us-east-2.amazonaws.com/pdf/Hindu_Temple_By_Laws.pdf" target="_blank" rel="noopener noreferrer">Bi-Laws</a></h3>
      <p className=" text-justify">The functioning of the Temple shall be in accordance with the listed Articles of Incorporation and By-laws.
        The Board of Trustees set the vision and create an endowment of a substantial amount to support the mission of the temple.
        Select the Board of Trustees or the Current Executive Committee links to see current list of members </p>
      <h3 className="title font-weight-bolder text-secondary"> <a href="/current" > Board Of Trustees </a></h3>
      <p className=" text-justify">The founding executive committee members were elected to executive office on January 16, 2000.
         The executive committee runs day to day operations of temple and strives to achieve the vision set by Board of Trustees for the temple.
         Follow the link below to see current and past executive committee members along with editors of Pranava and web masters for Temple website.</p>
      <h3 className="title font-weight-bolder text-secondary"> <a href="/current" > Current Executive Committee </a></h3>
      <h3 className="title font-weight-bolder text-secondary"> <a href="/past" > Past Executive Committees </a></h3>
      <h3 className="title font-weight-bolder text-secondary">Guidelines For Temple Facility Use</h3>
      <p className=" text-justify">The executive committee members have created following guidelines for Temple facility use by general body
        and consuming public interested in using the facility for social, religious, educational or fund raising purposes
        and these guidelines must be followed at all times without fail. Failure to follow these guidelines
        would result in banning the person(s) or group(s) from using the facility for a period of one year
        or more at the discretion of the executive committee.</p>

      <h3 className="title font-weight-bolder text-secondary">Contact Temple </h3>   
      <p className=" text-justify">To contact Temple Trustees, Executive Committee members or Priest, use following <a href="/forms">link</a></p>
      <p className=" text-justify">The directions to contact Temple could be found in the footer </p>
      <h3 className="title font-weight-bolder text-secondary">Donations to Temple</h3>
      <p className=" text-justify">Please use below Paypal link to make donations to Temple online.
         Your donations are secure and you can use Credit/Debit/ATM or Direct Debit for donation :</p>
      <MDBRow>
        <MDBCol md="12" className="mx-auto">
          <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
            <input type="hidden" name="cmd" value="_s-xclick" />
            <input type="hidden" name="hosted_button_id" value="RRMAMJJLD2XWA" />
            <input type="image" src="https://iowaht.s3-us-east-2.amazonaws.com/images/donate2.png" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" style={{ margin: "0 auto", display: "block", maxWidth: "300px" }} />
            <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
          </form>
        </MDBCol>
      </MDBRow>
      <h3 className="title font-weight-bolder text-secondary">Meeting Activities</h3>
      <p className=" text-justify">
        The Temple EC, BOT and GB meetings are conducted in open and free process and members
        of Temple General Body and guests are welcome to attend these meetings.
        The meeting dates and times are as shown below in Google Calendar.
        You can access this calendar from your iPhone, Android, Windows and Blackberry phone if you access your Gmail from those phones.
        Please send an email to Web Editor at econtact@iowahindutemple.org if you want your email added to this Google Calendar.
      </p>
    </MDBContainer>
  </>
)

export default ourtemple;
